<template>
 <div class="ml-1 pt-1">
  <div v-if="assignedFilterQueue && assignedFilterQueue.length" class="flex gap-2">
   <span
    v-for="search in assignedFilterQueue"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
   >
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterQueue && assignedFilterQueue.length > 1
       ? assignedFilterQueue.splice(assignedFilterQueue.indexOf(search), 1)
       : ((assignedFilterQueue.length = 0), this.$emit('update:searchQueue', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
    >
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div v-if="assignedFilterQueueName && assignedFilterQueueName.length" class="flex gap-2 pt-1">
   <span
    v-for="search in assignedFilterQueueName"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
   >
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterQueueName && assignedFilterQueueName.length > 1
       ? assignedFilterQueueName.splice(assignedFilterQueueName.indexOf(search), 1)
       : ((assignedFilterQueueName.length = 0), this.$emit('update:searchQueueName', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
    >
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div v-if="assignedFilterExtension && assignedFilterExtension.length" class="flex gap-2 pt-1">
   <span
    v-for="search in assignedFilterExtension"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
   >
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterExtension && assignedFilterExtension.length > 1
       ? assignedFilterExtension.splice(assignedFilterExtension.indexOf(search), 1)
       : ((assignedFilterExtension.length = 0), this.$emit('update:searchExtension', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
    >
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
  <div
   v-if="assignedFilterExtensionName && assignedFilterExtensionName.length"
   class="flex gap-2 pt-1"
  >
   <span
    v-for="search in assignedFilterExtensionName"
    :key="search"
    class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700"
   >
    {{ search }}
    <button
     type="button"
     @click="
      assignedFilterExtensionName && assignedFilterExtensionName.length > 1
       ? assignedFilterExtensionName.splice(assignedFilterExtensionName.indexOf(search), 1)
       : ((assignedFilterExtensionName.length = 0), this.$emit('update:searchExtensionName', null))
     "
     class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
    >
     <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
     </svg>
    </button>
   </span>
  </div>
 </div>
 <div class="flex flex-col shadow mt-1">
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
    <div class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
     <!-- <div
      v-if="selectedPeople.length > 0"
      class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 dark:bg-slate-700 sm:left-16"
     >
      <button
       type="button"
       class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      >
       Bulk edit
      </button>
      <button
       type="button"
       class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
      >
       Delete all
      </button>
     </div> -->
     <table
      v-if="tableData && tableData.length"
      class="min-w-full table-fixed divide-y divide-gray-300 dark:divide-slate-400 h-96"
     >
      <thead class="bg-gray-50 dark:bg-slate-700">
       <tr>
        <!-- <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
         <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          :checked="indeterminate || selectedPeople.length === tableData.length"
          :indeterminate="indeterminate"
          @change="selectedPeople = $event.target.checked ? tableData.map((p) => p.id) : []"
         />
        </th> -->

        <th
         v-for="head in finalHeader"
         :key="head"
         scope="col"
         class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 capitalize"
        >
         <span v-if="head === 'time'">
          <span v-if="selectedtime && selectedtime.length">
           {{ $t(head) }}
          </span>
         </span>
         <div class="text-left" v-else-if="head === 'queue'">
          <div as="div" class="mt-0">
           <div class="text-left">
            <button
             class="flex text-sm text-left font-semibold capitalize"
             @focus="openQueueFilter = true"
             @blur="openQueueFilter = false"
             @click="focusInputQueue()"
            >
             {{ $t(head) }}
             <FilterIcon
              class="flex-shrink-0 mt-0.5 h-4 w-4 hover:text-gray-500"
              aria-hidden="true"
             />
            </button>
           </div>
           <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
           >
            <div
             @mouseover="(openQueueFilterFocus = true), (isMouseOverFilter = true)"
             @mouseleave="(isMouseOverFilter = false), (openQueueFilterFocus = false)"
             v-show="openQueueFilter || openQueueFilterFocus"
             class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
            >
             <div class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
              <div class="relative">
               <div
                class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
               >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
               </div>
               <vue-select
                @focus="(openQueueFilter = true), (openQueueFilterFocus = true)"
                @blur="openQueueFilter = false"
                v-model="assignedFilterQueue"
                :options="queuesNameNumberArray"
                multiple
                maxHeight="200"
                close-on-select="true"
                searchable
                :class="['block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-20']"
                ref="inputSearchQueue"
               >
               </vue-select>
              </div>
             </div>
            </div>
           </transition>
          </div>
         </div>
         <div class="" v-else-if="head === 'queue_name'">
          <div as="div" class="mt-0">
           <div class="">
            <button
             class="flex justify-start text-sm font-semibold capitalize"
             @focus="openQueueNameFilter = true"
             @blur="openQueueNameFilter = false"
             @click="focusInputQueueName()"
            >
             {{ $t(head) }}
             <FilterIcon
              class="flex-shrink-0 mt-0.5 h-4 w-4 hover:text-gray-500"
              aria-hidden="true"
             />
            </button>
           </div>
           <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
           >
            <div
             @mouseover="(openQueueNameFilterFocus = true), (isMouseOverFilter = true)"
             @mouseleave="(isMouseOverFilter = false), (openQueueNameFilterFocus = false)"
             v-show="openQueueNameFilter || openQueueNameFilterFocus"
             class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
            >
             <div class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
              <div class="relative">
               <div
                class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
               >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
               </div>
               <vue-select
                @focus="(openQueueNameFilter = true), (openQueueNameFilterFocus = true)"
                @blur="openQueueNameFilter = false"
                v-model="assignedFilterQueueName"
                :options="queuesNameArray"
                multiple
                maxHeight="200"
                close-on-select="true"
                searchable
                :class="['block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-20']"
                ref="inputSearchQueueName"
               >
               </vue-select>
              </div>
             </div>
            </div>
           </transition>
          </div>
         </div>
         <div class="" v-else-if="head === 'extension'">
          <div as="div" class="mt-0">
           <div class="">
            <button
             class="flex justify-start text-sm font-semibold capitalize"
             @focus="openExtensionFilter = true"
             @blur="openExtensionFilter = false"
             @click="focusInputExtension()"
            >
             {{ $t(head) }}
             <FilterIcon
              class="flex-shrink-0 mt-0.5 h-4 w-4 hover:text-gray-500"
              aria-hidden="true"
             />
            </button>
           </div>
           <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
           >
            <div
             @mouseover="(openExtensionFilterFocus = true), (isMouseOverFilter = true)"
             @mouseleave="(isMouseOverFilter = false), (openExtensionFilterFocus = false)"
             v-show="openExtensionFilter || openExtensionFilterFocus"
             class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
            >
             <div class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
              <div class="relative">
               <div
                class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
               >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
               </div>
               <vue-select
                @focus="(openExtensionFilter = true), (openExtensionFilterFocus = true)"
                @blur="openExtensionFilter = false"
                v-model="assignedFilterExtension"
                multiple
                :options="extensionsNameNumberArray"
                maxHeight="200"
                close-on-select="true"
                searchable
                :class="['block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-20']"
                ref="inputSearchExtension"
               >
               </vue-select>
              </div>
             </div>
            </div>
           </transition>
          </div>
         </div>
         <div class="" v-else-if="head === 'element_name'">
          <div as="div" class="mt-0">
           <div class="">
            <button
             class="flex justify-start text-sm font-semibold capitalize"
             @focus="openExtensionNameFilter = true"
             @blur="openExtensionNameFilter = false"
             @click="focusInputExtensionName()"
            >
             {{ $t(head) }}
             <FilterIcon
              class="flex-shrink-0 mt-0.5 h-4 w-4 hover:text-gray-500"
              aria-hidden="true"
             />
            </button>
           </div>
           <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
           >
            <div
             @mouseover="(openExtensionNameFilterFocus = true), (isMouseOverFilter = true)"
             @mouseleave="(isMouseOverFilter = false), (openExtensionNameFilterFocus = false)"
             v-show="openExtensionNameFilter || openExtensionNameFilterFocus"
             class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
            >
             <div class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
              <div class="relative">
               <div
                class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-30"
               >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
               </div>
               <vue-select
                @focus="(openExtensionNameFilter = true), (openExtensionNameFilterFocus = true)"
                @blur="openExtensionNameFilter = false"
                v-model="assignedFilterExtensionName"
                multiple
                :options="extensionsNameArray"
                close-on-select="true"
                maxHeight="200"
                searchable
                :class="['block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-20']"
                ref="inputSearchExtensionName"
               >
               </vue-select>
              </div>
             </div>
            </div>
           </transition>
          </div>
         </div>
         <span v-else>
          <span v-if="head === 'declined'" class="flex flex-col text-left">
           <span>
            {{ $t("Déclinés") + "(E)" }}
           </span>
           <span>
            {{ $t("Dissuadés") + "(F)" }}
           </span>
          </span>
          <span v-else class="cursor-pointer inline-flex">
           <span>{{ $t(head) }}</span>
           <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            :class="['ml-2 h-5 w-5']"
            viewBox="0 0 20 20"
            fill="currentColor"
           >
            <path
             :d="[
              'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
             ]"
            />
           </svg> -->
          </span>
         </span>
        </th>
        <!-- <th
         scope="col"
         class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 capitalize"
        >
         <div>test</div>
        </th> -->
       </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400">
       <tr
        v-for="data in dataDurationRanges"
        :key="data.id"
        :class="[
         data.queue == data.element && !queuesOnlyStats
          ? 'bg-gray-100 dark:bg-slate-400 border-2 '
          : '',
         'hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer',
        ]"
       >
        <!-- <td class="relative w-12 px-6 sm:w-16 sm:px-8">
         <div
          v-if="selectedPeople.includes(data.id)"
          class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
         ></div>
         <input
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
          :value="data.id"
          v-model="selectedPeople"
         />
        </td> -->
        <td
         @click="$emit('clickOn', data.id)"
         v-for="info in finalBody"
         :key="info"
         :class="[
          data.queue == data.element && !queuesOnlyStats
           ? 'bg-gray-100 dark:bg-slate-400 border-t-2 border-b-2 text-gray-700 font-medium'
           : 'text-gray-500 dark:text-slate-100',
          'whitespace-nowrap px-3 py-4 text-sm ',
         ]"
        >
         <span v-if="info.includes('duration')">
          <span v-if="info === 'avg_talking_duration'">
           {{
            data["talking_duration"] &&
            parseInt(data["talking_duration"]) > 0 &&
            (data["keyType"] == 0
             ? data["answered"] && parseInt(data["answered"]) > 0
             : data["transferred"] && parseInt(data["transferred"]) > 0)
             ? this.formatSecsToMinAndSecs(
                parseInt(data["talking_duration"]) /
                 (data["keyType"] == 0 ? parseInt(data["answered"]) : parseInt(data["transferred"]))
               )
             : 0
           }}
          </span>
          <span v-else-if="info === 'avg_waiting_duration'">
           {{
            data["waiting_duration"] &&
            parseInt(data["waiting_duration"]) > 0 &&
            (data["keyType"] == 0
             ? (data["answered"] && parseInt(data["answered"]) > 0) ||
               (data["declined_solicitations"] && parseInt(data["declined_solicitations"]) > 0)
             : data["answered"] && parseInt(data["answered"]) > 0)
             ? this.formatSecsToMinAndSecs(
                parseInt(data["waiting_duration"]) /
                 (data["keyType"] == 0
                  ? (data["answered"] ? parseInt(data["answered"]) : 0) +
                    (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0)
                  : parseInt(data["answered"]))
               )
             : 0
           }}
          </span>

          <span v-else>
           {{
            data[info]
             ? parseInt(data[info]) < 3600
               ? this.formatSecsToMinAndSecs(data[info])
               : this.formatSecsToHourMinAndSecs(parseInt(data[info]))
             : 0
           }}
          </span>
         </span>
         <span v-else-if="info === 'answered_percentage_ext'">
          {{
           data["keyType"] == 0
            ? (
               (parseInt(data["answered"] ? data["answered"] : 0) /
                (parseInt(data["answered"] ? data["answered"] : 0) +
                 parseInt(data["declined_solicitations"] ? data["declined_solicitations"] : 0))) *
               100
              ).toFixed(0)
            : (
               (parseInt(data["transferred"] ? data["transferred"] : 0) /
                ((data["answered"] ? parseInt(data["answered"]) : 0) +
                 (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0) -
                 (data["abandoned"] ? parseInt(data["abandoned"]) : 0))) *
               100
              ).toFixed(0)
          }}%
         </span>
         <span v-else-if="info === 'answered_percentage_queue'">
          {{
           (
            (parseInt(data["transferred"] ? data["transferred"] : 0) /
             ((data["answered"] ? parseInt(data["answered"]) : 0) +
              (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0) -
              (data["abandoned"] ? parseInt(data["abandoned"]) : 0))) *
            100
           ).toFixed(0)
          }}%
         </span>
         <span v-else-if="info === 'formatedTime'">
          <span v-if="selectedtime && selectedtime.length">
           {{ data[info] ? data[info] : 0 }}
          </span>
         </span>
         <span v-else-if="info === 'solicited'">
          <span>
           {{
            data["keyType"] == 0
             ? (data["answered"] ? parseInt(data["answered"]) : 0) +
               (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0)
             : (data["answered"] ? parseInt(data["answered"]) : 0) +
               (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0) -
               (data["abandoned"] ? parseInt(data["abandoned"]) : 0)
           }}
          </span>
         </span>
         <span v-else-if="info === 'calls_ext'">
          <span>
           {{
            data["keyType"] == 0
             ? (data["answered_calls"] ? parseInt(data["answered_calls"]) : 0) +
               (data["declined_calls"] ? parseInt(data["declined_calls"]) : 0)
             : (data["answered"] ? parseInt(data["answered"]) : 0) +
               (data["declined_solicitations"] ? parseInt(data["declined_solicitations"]) : 0)
           }}
          </span>
         </span>
         <span v-else-if="info === 'time' && data['time']">
          <span>
           {{
            parseInt(data["time"].slice(0, 2)) - this.hostTimeZone >= 10
             ? parseInt(data["time"].slice(0, 2)) - this.hostTimeZone + ":" + data["time"].slice(-2)
             : "0" +
               (parseInt(data["time"].slice(0, 2)) - this.hostTimeZone) +
               ":" +
               data["time"].slice(-2)
           }}
          </span>
         </span>
         <span v-else-if="info === 'answered' && !queuesOnlyStats">
          <span>
           {{
            data["keyType"] == 0
             ? data[info]
               ? parseInt(data[info])
               : 0
             : data["transferred"]
             ? parseInt(data["transferred"])
             : 0
           }}
          </span>
         </span>
         <span v-else-if="info === 'declined_solicitations'">
          <span>
           {{
            data["keyType"] == 0
             ? data[info]
               ? parseInt(data[info])
               : 0
             : data["dissuaded"]
             ? parseInt(data["dissuaded"])
             : 0
           }}
          </span>
         </span>
         <span v-else-if="info === 'abandoned'">
          <span v-if="data['keyType'] == 4">
           {{ data[info] ? data[info] : 0 }}
          </span>
          <MinusSmIcon
           v-else
           class="flex-shrink-0 mt-0.5 h-4 w-4 hover:text-gray-500"
           aria-hidden="true"
          />
         </span>
         <span v-else-if="info === 'elementNameNumber'">
          <span>
           {{ data["keyType"] == 0 ? (data[info] ? data[info] : 0) : "Full Queue" }}
          </span>
         </span>
         <span v-else-if="info.includes('range')">
          <span v-if="info.includes('talking')">
           {{ data[info] ? data[info] : 0 }} ({{
            data["keyType"] == 0
             ? (
                (parseInt(data[info] ? data[info] : 0) /
                 parseInt(data["answered"] ? data["answered"] : 0)) *
                100
               ).toFixed(0)
             : (
                (parseInt(data[info] ? data[info] : 0) /
                 parseInt(data["transferred"] ? data["transferred"] : 0)) *
                100
               ).toFixed(0)
           }}%)
          </span>
          <span v-if="info.includes('waiting')">
           {{ data[info] ? data[info] : 0 }} ({{
            data["keyType"] == 0
             ? (
                (parseInt(data[info] ? data[info] : 0) /
                 ((data["answered_calls"] ? parseInt(data["answered_calls"]) : 0) +
                  (data["declined_calls"] ? parseInt(data["declined_calls"]) : 0))) *
                100
               ).toFixed(0)
             : (
                (parseInt(data[info] ? data[info] : 0) /
                 ((data["answered"] ? parseInt(data["answered"]) : 0) +
                  (data["declined_solicitations"]
                   ? parseInt(data["declined_solicitations"])
                   : 0))) *
                100
               ).toFixed(0)
           }}%)
          </span>
         </span>
         <span v-else>
          <span v-if="info !== 'time' && info !== 'formatedDate'">
           {{ data[info] ? data[info] : 0 }}
          </span>
          <span v-if="info === 'formatedDate'">
           {{ data[info] ? data[info] : parseDateRange(period) }}
          </span>
          <span v-if="info === 'time' && !data['time'] && selectedtime && selectedtime.length > 1">
           {{
            selectedtime[0].slice(0, 2) +
            ":" +
            selectedtime[0].slice(-2) +
            "-" +
            selectedtime[1].slice(0, 2) +
            ":" +
            selectedtime[1].slice(-2)
           }}
          </span>
         </span>
        </td>
       </tr>
      </tbody>
      <!-- <tbody
       v-else
       class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400"
      >
       <tr :class="['hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer']">
        <div class="text-center p-10">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="mx-auto h-12 w-12 text-gray-400"
         >
          <path
           stroke-linecap="round"
           stroke-linejoin="round"
           d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
          />
         </svg>
         <h3 class="mt-2 text-lg font-medium text-gray-900">
          {{ $t("noDataFoundTitle") }}
         </h3>
         <p class="mt-1 text-base text-gray-500"></p>
        </div>
       </tr>
      </tbody> -->
     </table>
     <div v-else class="text-center p-10 bg-white">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"
       stroke-width="1"
       stroke="currentColor"
       class="mx-auto h-12 w-12 text-gray-400"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
       />
      </svg>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       {{ $t("noDataFoundTitle") }}
      </h3>
      <p class="mt-1 text-base text-gray-500"></p>
     </div>
     <!-- <BackendTablePagination
      :to="this.to"
      :from="this.from"
      :total="this.total"
      :currentPage="currentPage"
      :links="links"
      @select-page="$emit('selectPage', $event)"
      @select-rows="$emit('selectRows', $event)"
     /> -->
    </div>
   </div>
  </div>
 </div>
</template>

<script>
// import BackendTablePagination from "./BackendTablePagination.vue";
import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import { FilterIcon } from "@heroicons/vue/solid";
import { MinusSmIcon } from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
export default {
 props: [
  "header",
  "body",
  "tableData",
  "to",
  "from",
  "total",
  "currentPage",
  "links",
  "selectedPeople",
  "indeterminate",
  "selectedtime",
  "queuesNameArray",
  "queuesArray",
  "extensionsArray",
  "extensionsNameArray",
  "searchQueue",
  "searchQueueName",
  "searchExtension",
  "searchExtensionName",
  "extensionsNameNumberArray",
  "queuesNameNumberArray",
  "period",
  "parseDateRange",
  "queuesOnlyStats",
  "timeRange",
  "timeGap",
  "numberOfColumns",
  "selectedRangeType",
 ],
 components: { VueSelect, FilterIcon, MinusSmIcon },
 data() {
  return {
   selectedPeople: [],
   assignedFilterQueue: [],
   assignedFilterQueueName: [],
   assignedFilterExtension: [],
   assignedFilterExtensionName: [],
   openQueueFilter: false,
   openQueueFilterFocus: false,
   openQueueNameFilter: false,
   openQueueNameFilterFocus: false,
   openExtensionFilter: false,
   openExtensionFilterFocus: false,
   openExtensionNameFilter: false,
   openExtensionNameFilterFocus: false,
   isMouseOverFilter: false,
  };
 },
 methods: {
  focusInputQueue() {
   if (this.$refs.inputSearchQueue) {
    this.$refs.inputSearchQueue[0].focus();
   }
  },
  focusInputQueueName() {
   if (this.$refs.inputSearchQueueName) {
    this.$refs.inputSearchQueueName[0].focus();
   }
  },
  focusInputExtension() {
   if (this.$refs.inputSearchExtension) {
    this.$refs.inputSearchExtension[0].focus();
   }
  },
  focusInputExtensionName() {
   if (this.$refs.inputSearchExtensionName) {
    this.$refs.inputSearchExtensionName[0].focus();
   }
  },
 },
 watch: {
  selectedPeople: function (val) {
   this.$emit("update:selectedPeople", val);
  },
  assignedFilterQueue: function (val) {
   if (val && val.length > 0) {
    console.log("file: QueueFullReportTable.vue:573 ~ val:", val);
    this.$emit("update:searchQueue", val);
   } else {
    this.$emit("update:searchQueue", null);
   }
  },
  assignedFilterQueueName: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchQueueName", val);
   } else {
    this.$emit("update:searchQueueName", null);
   }
  },
  assignedFilterExtension: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchExtension", val);
   } else {
    this.$emit("update:searchExtension", null);
   }
  },
  assignedFilterExtensionName: function (val) {
   if (val && val.length > 0) {
    this.$emit("update:searchExtensionName", val);
   } else {
    this.$emit("update:searchExtensionName", null);
   }
  },
 },
 mounted() {
  if (this.searchQueue) {
   this.assignedFilterQueue = this.searchQueue;
  }
  if (this.searchQueueName) {
   this.assignedFilterQueueName = this.searchQueueName;
  }
  if (this.searchExtension) {
   this.assignedFilterExtension = this.searchExtension;
  }
  if (this.searchExtensionName) {
   this.assignedFilterExtensionName = this.searchExtensionName;
  }
 },
 computed: {
  ...mapGetters(["hostName", "hostTimeZone", "pbxPeriodSelected", "pbxElements", "pbxMap"]),
  finalBody() {
   let slideTimeRange = parseInt(this.timeRange);
   let tableColumnNumber = parseInt(this.numberOfColumns);
   const timeGap = parseInt(this.timeGap);
   const maxTimeRange = slideTimeRange - timeGap + tableColumnNumber * timeGap;
   let durationRanges = [];
   let time1Talking = 0 + slideTimeRange;
   let time2Talking = timeGap + slideTimeRange;
   let time1Waiting = 0 + slideTimeRange;
   let time2Waiting = timeGap + slideTimeRange;
   if (this.selectedRangeType === "talking") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "talking_range_" + time1Talking;
     durationRanges.push(column);
     time1Talking = time1Talking + timeGap;
    }
   }
   if (this.selectedRangeType === "waiting") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column = "waiting_range_" + time1Waiting;
     durationRanges.push(column);
     time1Waiting = time1Waiting + timeGap;
    }
   }
   let tableBody = [...this.body, ...durationRanges];
   return tableBody;
  },
  finalHeader() {
   let slideTimeRange = parseInt(this.timeRange);
   let tableColumnNumber = parseInt(this.numberOfColumns);
   const timeGap = parseInt(this.timeGap);
   const maxTimeRange = slideTimeRange - timeGap + tableColumnNumber * timeGap;
   let durationRanges = [];
   let time1Talking = 0 + slideTimeRange;
   let time2Talking = timeGap + slideTimeRange;
   let time1Waiting = 0 + slideTimeRange;
   let time2Waiting = timeGap + slideTimeRange;
   let startTimeNextTalking = 0;
   let startTimeNextWaiting = 0;
   if (this.selectedRangeType === "talking") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column =
      index == tableColumnNumber - 1
       ? "appel" + " " + time1Talking + "+"
       : "appel" + " " + startTimeNextTalking + "-" + time1Talking;
     durationRanges.push(column);
     startTimeNextTalking = time1Waiting + 1;
     time1Talking = time1Talking + timeGap;
    }
   }
   if (this.selectedRangeType === "waiting") {
    for (let index = 0; index < tableColumnNumber; index++) {
     let column =
      index == tableColumnNumber - 1
       ? "attente" + " " + time1Waiting + "+"
       : "attente" + " " + startTimeNextWaiting + "-" + time1Waiting;
     durationRanges.push(column);
     startTimeNextWaiting = time1Waiting + 1;
     time1Waiting = time1Waiting + timeGap;
    }
   }
   let tableHeader = [...this.header, ...durationRanges];
   return tableHeader;
  },
  dataDurationRanges() {
   let arrData = [];
   let slideTimeRange = parseInt(this.timeRange);
   let tableColumnNumber = parseInt(this.numberOfColumns);
   const timeGap = parseInt(this.timeGap);
   const maxTimeRange = slideTimeRange - timeGap + tableColumnNumber * timeGap;
   console.log(
    "file: QueueFullReportTable.vue:890 ~ dataDurationRanges ~ maxTimeRange:",
    maxTimeRange
   );
   for (let index = 0; index < this.tableData.length; index++) {
    let data = {};
    let durationRangesData = {};
    let time1Talking = 0 + slideTimeRange;
    let time2Talking = timeGap + slideTimeRange;
    let time1Waiting = 0 + slideTimeRange;
    let time2Waiting = timeGap + slideTimeRange;
    let startTimeNextTalking = 0;
    let startTimeNextWaiting = 0;
    const element = this.tableData[index];
    let keys = Object.keys(element);
    for (let index = 0; index < tableColumnNumber; index++) {
     let talkingRangeValue = 0;
     let waitingRangeValue = 0;
     let talkingMaxRangeValue = 0;
     let waitingMaxRangeValue = 0;
     keys.forEach((key) => {
      if ((key.includes("talking") || key.includes("waiting")) && !key.includes("duration")) {
       let split = key.split("_");
       let timeRange1 = parseInt(split[1]);
       let timeRange2 = parseInt(split[2]);
       //  console.log("file: QueueFullReportTable.vue:910 ~ keys.forEach ~ timeRange1:", timeRange1);
       //  console.log("file: QueueFullReportTable.vue:916 ~ keys.forEach ~ timeRange2:", timeRange2);
       //  console.log(
       //   "file: QueueFullReportTable.vue:912 ~ keys.forEach ~ time1Talking:",
       //   time1Talking
       //  );
       //  console.log(
       //   "file: QueueFullReportTable.vue:920 ~ keys.forEach ~ startTimeNextWaiting:",
       //   startTimeNextWaiting
       //  );
       console.log(
        "file: QueueFullReportTable.vue:945 ~ dataDurationRanges ~ startTimeNextTalking:",
        startTimeNextTalking
       );
       if (timeRange2 <= time1Talking && timeRange1 >= startTimeNextTalking) {
        if (key.includes("talking")) {
         talkingRangeValue = talkingRangeValue + parseInt(element[key]);
        } else {
         waitingRangeValue = waitingRangeValue + parseInt(element[key]);
        }
       }
       if (maxTimeRange <= timeRange1) {
        if (key.includes("talking")) {
         talkingMaxRangeValue = talkingMaxRangeValue + parseInt(element[key]);
        } else {
         waitingMaxRangeValue = waitingMaxRangeValue + parseInt(element[key]);
        }
       }
      }
     });
     if (index < tableColumnNumber - 1) {
      durationRangesData[`${"talking_range" + "_" + time1Talking}`] = talkingRangeValue;
      durationRangesData[`${"waiting_range" + "_" + time1Talking}`] = waitingRangeValue;
     } else {
      durationRangesData[`${"talking_range" + "_" + time1Talking}`] = talkingMaxRangeValue;
      durationRangesData[`${"waiting_range" + "_" + time1Talking}`] = waitingMaxRangeValue;
     }
     startTimeNextTalking = time1Talking + 1;
     time1Talking = time1Talking + timeGap;
    }
    data = { ...element, ...durationRangesData };
    arrData.push(data);
   }
   console.log("file: QueueFullReportTable.vue:836 ~ dataDurationRanges ~ arrData:", arrData);
   return arrData;
  },
 },
};
</script>

<style></style>
